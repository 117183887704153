import {useEffect, useState} from "react";

export default function useThumbnailSpread({bookInfo}) {
    const [thumbnails, setThumbnails] = useState([]);

    useEffect(() => {
        // prepare thumbnails in spread
        if (!bookInfo?.thumbnails || bookInfo?.thumbnails.length < 2) return;

        const thumbnailSpread = [];
        const thumbnailCopy = bookInfo?.thumbnails.slice();
        const firstPage = thumbnailCopy.shift();
        const lastPage = thumbnailCopy.pop();

        thumbnailSpread.push({
            single: true,
            thumbnails: [firstPage],
            index: 0,
        });

        for (let i = 0; i < thumbnailCopy.length; i += 2) {
            thumbnailSpread.push({
                single: false,
                index: i + 1,
                thumbnails: [thumbnailCopy[i], thumbnailCopy[i + 1]],
            });
        }

        thumbnailSpread.push({
            single: true,
            thumbnails: [lastPage],
            index: bookInfo?.thumbnails.length - 1,
        });

        setThumbnails(thumbnailSpread);
    }, [bookInfo]);

    return thumbnails;
}
