import {IoBook, IoDocument, IoGrid} from "react-icons/io5";
import React from "react";
import {classNames} from "@/components/common/Helpers";

export default function FlipBookNav({ isPortrait, onThumbnailToggle, onPortraitToggle }) {
    return (
        <div className="fixed top-0 md:hidden flex gap-3 m-5 text-black">
            <IconButton
                onClick={onThumbnailToggle}
            >
                <IoGrid
                    size={24}
                />
            </IconButton>

            <ButtonGroup>
                <GroupButton
                    active={!isPortrait}
                    onClick={onPortraitToggle}
                >
                    <IoBook
                        size={24}
                    />
                </GroupButton>

                <GroupButton
                    active={isPortrait}
                    onClick={onPortraitToggle}
                >
                    <IoDocument
                        size={24}
                    />
                </GroupButton>
            </ButtonGroup>

        </div>
    );
}

const ButtonGroup = ({children}) => {
    return (
        <div className="flex bg-white rounded shadow overflow-hidden divide-x">
            {children}
        </div>
    );
}

const GroupButton = ({children, onClick, active}) => {
    return (
        <button
            className={classNames(
                "p-2 px-4",
                active ? 'text-blue-600' : '',
            )}
            onClick={active ? () => {} : onClick}
        >
            {children}
        </button>
    );
};

const IconButton = ({children, onClick}) => {
    return (
        <button
            className="p-2"
            onClick={onClick}
        >
            {children}
        </button>
    );
};
