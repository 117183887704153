import React, {memo, useEffect, useRef, useState} from "react";
import HTMLFlipBook from "react-pageflip";
import FlipBookPage from "@/public_obituary/book/flip-book/FlipBookPage";
import useWindowResize from "@/public_obituary/book/flip-book/hooks/useWindowResize";

const ReactPageFlipWrap = memo(
    function ({obituary, setPageFlip, pages, events, pageWidth, isPortrait, parentRef}) {
        const pageRef = useRef(1);
        const [maxWidth, setMaxWidth] = useState(null);
        const [pageChildren, setPageChildren] = useState(null);

        useWindowResize(() => {
            const parentWidth = parentRef.clientWidth;
            const k = isPortrait ? 1 : 2;
            const ratio = 11 / (8.5 * k);
            // constrain book to be no more than 75% of viewport height
            const widthFromHeight = ((window.innerHeight * 0.75) / ratio);
            setMaxWidth(Math.floor(Math.min(parentWidth, widthFromHeight)));
        }, [parentRef, isPortrait], 300);

        // this is to prevent HTMLFlipBook from flickering
        useEffect(() => {
            setPageChildren(pages.map((page) => (
                <FlipBookPage
                    key={page}
                    obituaryUuid={obituary?.id}
                    page={page}
                    initialPageWidth={pageWidth}
                    events={events.current}
                    pageCount={pages.length}
                    hard={page === 1 && page === pages.length}
                />
            )));
        }, [obituary, pages.length]);

        return (
            <div className="relative w-full" style={{maxWidth}}>
                <HTMLFlipBook
                    className={"left z-10"}
                    key={obituary?.id}
                    extraKey={[isPortrait, maxWidth].join(',')}
                    size="stretch"
                    flippingTime={650}
                    width={1700}
                    height={2200}
                    // this forces min page width to be parent size.
                    minWidth={isPortrait ? maxWidth : 0}
                    maxShadowOpacity={0.4}
                    showCover={true}
                    disableFlipByClick={true}
                    onFlip={(e) => {
                        const pageNumber = e.data + 1;
                        pageRef.current = pageNumber;
                        events.current.dispatchEvent(new CustomEvent('flip', {detail: pageNumber}));
                        updateBookCentering(pageNumber, pages.length);
                        // This allows browser to pre-render adjacent spreads.
                        markReadyPages(pageNumber);
                    }}
                    onInit={(e) => {
                        e.object.turnToPage(pageRef.current - 1);
                        updateBookCentering(pageRef.current, pages.length);
                        setPageFlip(e.object);
                        events.current.dispatchEvent(new CustomEvent('init', { detail: e.object }));
                    }}
                >
                    {pageChildren}
                </HTMLFlipBook>
            </div>
        );
    }
)

function updateBookCentering(page, count) {
    const flipBookContainer = document.querySelector('.stf__parent');
    if (flipBookContainer) {
        if (page === 1) {
            flipBookContainer.classList.remove('right');
            flipBookContainer.classList.add('left');
        } else if (page === count) {
            flipBookContainer.classList.remove('left');
            flipBookContainer.classList.add('right');
        } else {
            flipBookContainer.classList.remove('left');
            flipBookContainer.classList.remove('right');
        }
    }
}

function markReadyPages(pageNumber) {
    document.querySelectorAll(`.page.ready`)
        ?.forEach((el) => {
            el?.classList?.remove('ready');
        });

    const pagesToShow = [pageNumber - 2, pageNumber - 1, pageNumber + 2, pageNumber + 3];
    pagesToShow.forEach(page => {
        document.querySelector(`.page-${page}`)?.classList.add('ready');
    });
}

export default ReactPageFlipWrap;
