import {useEffect} from "react";

export default function useWindowResize(fn, deps, debounceMs = 0) {
    useEffect(() => {
        let timeout;
        const handler = () => {
            clearTimeout(timeout);
            timeout = setTimeout(fn, debounceMs);
        }

        window.addEventListener('resize', handler);
        window.addEventListener('orientationchange', handler);
        fn();

        return () => {
            clearTimeout(timeout);
            window.removeEventListener('resize', handler);
            window.removeEventListener('orientationchange', handler);
        };
    }, deps);
}
