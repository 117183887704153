import {classNames} from "@/components/common/Helpers";
import React, {useEffect, useState} from "react";

export default function Modal({isOpen, onClose, children}) {
    const [animateIn, setAnimateIn] = useState(false);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        let timeout;
        if (isOpen) {
            setIsVisible(true); // Ensure the modal is visible before starting the animation in
            timeout = setTimeout(() => setAnimateIn(true), 10); // Start animation slightly after making modal visible
        } else {
            setAnimateIn(false); // Start animation out
            timeout = setTimeout(() => setIsVisible(false), 700); // Set modal to not visible after animation ends
        }

        return () => clearTimeout(timeout);
    }, [isOpen]);

    useEffect(() => {
        window.addEventListener('modal-close', onClose);
        return () => {
            window.removeEventListener('modal-close', onClose);
        };
    }, []);

    return (
        <div
            className={classNames(
                "transition-opacity duration-700 bg-gray-300 md:bg-black/70 hs-overlay h-full w-full fixed top-0 start-0 z-[1000] overflow-x-hidden overflow-y-auto flex items-center md:p-5",
                animateIn ? "opacity-100" : "opacity-0",
                isVisible ? "visible" : "invisible",
            )}
            tabIndex="-1"
        >
            <button
                onClick={onClose}
                type="button"
                className="z-10 m-5 fixed top-0 right-0 flex justify-center items-center w-10 h-10 text-sm font-semibold rounded-full bg-white text-black hover:opacity-50 shadow"
            >
                <span className="sr-only">Close</span>
                <svg className="flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24"
                     height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2"
                     strokeLinecap="round" strokeLinejoin="round">
                    <path d="M18 6 6 18"></path>
                    <path d="m6 6 12 12"></path>
                </svg>
            </button>

            <div
                className="my-10 max-w-screen-2xl w-full mx-auto text-white relative"
            >
                {children}
            </div>

            {animateIn && (
                <style dangerouslySetInnerHTML={{
                    __html: `
                          html {
                            height: 100%;
                            overflow: hidden;
                          }
                        `
                }}></style>
            )}
        </div>
    );
}
