import React, {useEffect, useState} from "react";
import {classNames} from "@/components/common/Helpers";
import Skeleton from "react-loading-skeleton";

export default function FlipBookThumbnails({thumbnails, pageFlip, events, onClick}) {
    const [pageIndex, setPageIndex] = useState(1);

    useEffect(() => {
        if (!events) return;
        const flipHandler = (e) => {
            const currentPage = e.detail;
            setPageIndex(currentPage);
        };
        events.addEventListener('flip', flipHandler);

        return () => events.removeEventListener('flip', flipHandler);
    }, [events]);

    return thumbnails && thumbnails?.length > 0 ? (
        <div className="overflow-y-scroll absolute h-full w-full">
            {thumbnails.map(({thumbnails, single, index}) => (
                <div className="p-6 pb-0" key={index}>
                    <button onClick={() => {
                        typeof onClick === 'function' && onClick(index);
                        setPageIndex(index + 1);
                        pageFlip?.flip(index);
                    }} className="w-full">
                        <span className="sr-only">Go to page {index + 1}</span>
                        <div
                            className={classNames(
                                'w-full flex justify-center bg-gray-100 shadow-sm shadow-black/5 rounded overflow-hidden',
                                pageIndex === index + 1 ? 'outline outline-blue-600' : 'outline outline-gray-200'
                            )}>
                            {single ? (
                                <div className="flex-1 min-h-[50px] text-center">
                                    <img
                                        src={thumbnails[0]?.url}
                                        className="m-auto w-1/2 shadow-sm shadow-black/20"
                                        alt={`Page ${index + 1}`}
                                    />
                                </div>
                            ) : (
                                <>
                                    <div className="flex-1 min-h-[50px]">
                                        <img
                                            src={thumbnails[0]?.url}
                                            className="shadow-sm shadow-black/20"
                                            alt={`Page ${index + 1}`}
                                        />
                                    </div>

                                    <div className="flex-1 min-h-[50px]">
                                        <img
                                            src={thumbnails[1]?.url}
                                            className="shadow-sm shadow-black/20"
                                            alt={`Page ${index + 1}`}
                                        />
                                    </div>
                                </>
                            )}

                        </div>
                    </button>
                </div>
            ))}
            <div className={"pb-6"}></div>
        </div>
    ) : (
        <div className="absolute h-full w-full p-7 overflow-hidden">
            <p className="text-center text-black mb-5 font-bold uppercase text-xs text-gray-400 tracking-wide">
                Generating Thumbnails...
            </p>
            <Skeleton
                height={120}
                count={20}
            />
        </div>
    );
}
